import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoaderService } from '@shared/services/loader.service';
import { UserServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Injectable()
export class FiscalCodeAvailableAsyncValidator {
    constructor(
        private loaderService: LoaderService,
        private userServiceProxy: UserServiceProxy,
    ) {}

    validate(userId?: number): AsyncValidatorFn {
        return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
            this.loaderService.deactivate();
            const isWhiteString = !`${control.value}`.replace(/\s/g, '').length;
            if (!isWhiteString) {
                const fiscalCode = control.value as string;
                return this.userServiceProxy.isFiscalCodeCorrect(fiscalCode, userId).pipe(
                    map((allowed) => (allowed ? null : { fiscalCodeAlreadyExists: true })),
                    finalize(() => this.loaderService.activate()),
                );
            } else {
                return of(null);
            }
        };
    }
}
